import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="bg-[url('/images/bot-bg.png')] bg-cover lg:p-[9rem] lg:mt-[-3rem]">
        
        <h2 className="text-center teamtitle text-white">
        Privacy Policy
          </h2>
          
         
                </div>
    
<Container>
<div className="bg-grey-500 mt-[3rem]">
  

         
          <p className="ptp">Protecting your private information is our priority. This Statement of Privacy applies to 
rapidautomation.ai, and Rapid Acceleration Partners, inc. and governs data collection and usage. 
For the purposes of this Privacy Policy, unless otherwise noted, all references to Rapid 
Acceleration Partners, inc. include rapidautomation.ai. The Rapid Acceleration Partners, inc. 
website is a Company Website site. By using the Rapid Acceleration Partners, inc. website, you 
consent to the data practices described in this statement.</p>

     <h2 className="pttitle">
     Privacy Compliance Certification
          </h2>
          <p className="ptp">Rapid Acceleration Partners, inc. has soc2 certification. 
  
  </p> 
          <p className="ptp">English</p>
          <h2 className="pttitle"> Collection of your Personal Information </h2>
          <p className="ptp">Collection of your Personal Information </p>
          <h2 className="pttitle">Sharing Information with Third Parties </h2>
          <p className="ptp">Sharing Information with Third Parties </p>
          <p className="ptp">Rapid Acceleration Partners, inc. may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Rapid Acceleration Partners, inc., and they are required to maintain the confidentiality of your information. </p>
          <p className="ptp">Rapid Acceleration Partners, inc. may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Rapid Acceleration Partners, inc. or the site; (b) protect and defend the rights or property of Rapid Acceleration Partners, inc.; and/or (c) act under exigent circumstances to protect the personal safety of users of Rapid Acceleration Partners, inc., or the public. </p>

          <h2 className="pttitle">Automatically Collected Information </h2>
          <p className="ptp">Information about your computer hardware and software may be automatically collected by Rapid Acceleration Partners, inc.. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Rapid Acceleration Partners, inc. website. </p>
          <h2 className="pttitle">Use of Cookies </h2>
          <p className="ptp">The Rapid Acceleration Partners, inc. website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. </p>
          <p className="ptp">One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Rapid Acceleration Partners, inc. pages, or register with Rapid Acceleration Partners, inc. site or services, a cookie helps Rapid Acceleration Partners, inc. to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Rapid Acceleration Partners, inc. website, the information you previously provided can be retrieved, so you can easily use the Rapid Acceleration Partners, inc. features that you customized. </p>
          <p className="ptp">You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Rapid Acceleration Partners, inc. services or websites you visit. </p>


          <h2 className="pttitle">Links </h2>
          <p className="ptp">This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. </p>
          <h2 className="pttitle">Security of your Personal Information </h2>
          <p className="ptp">Rapid Acceleration Partners, inc. secures your personal information from unauthorized access, use, or disclosure. Rapid Acceleration Partners, inc. uses the following methods for this purpose: 
  
  -	SSL Protocol 
 </p>
          <p className="ptp">When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. 
  
  </p>
          <p className="ptp">
            
            
          We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. 
          </p>
          <h2 className="pttitle">Right to Deletion  </h2>
          <p className="ptp">Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:   </p>
          

          <p className="ptp">Delete your personal information from our records; and 
            Direct any service providers to delete your personal information from their records</p>
            <p className="ptp">Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: </p>
            <p className="ptp">Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </p>
            <p className="ptp">Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; </p>
            <p className="ptp">Debug to identify and repair errors that impair existing intended functionality; </p>
            <p className="ptp">Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </p>
            <p className="ptp">Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </p>
            <p className="ptp">Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </p>
            <p className="ptp">Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </p>
            <p className="ptp">Comply with an existing legal obligation; or </p>
            <p className="ptp">Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. </p>
          

          <h2 className="pttitle">Children Under Thirteen </h2>
          <p className="ptp">Rapid Acceleration Partners, inc. does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. </p>
          <h2 className="pttitle">E-mail Communications </h2>
          <p className="ptp">From time to time, Rapid Acceleration Partners, inc. may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Rapid Acceleration Partners, inc. or click on a link therein. </p>
          <p className="ptp">If you would like to stop receiving marketing or promotional communications via email from Rapid Acceleration Partners, inc., you may opt out of such communications by Replying to the mail and asking to do so. </p>

          <h2 className="pttitle">Contact Information </h2>
          <p className="ptp">Rapid Acceleration Partners, inc. welcomes your questions or comments regarding this Statement of Privacy. If you believe that Rapid Acceleration Partners, inc. has not adhered to this Statement, please contact Rapid Acceleration Partners, inc. at: </p>
          <p className="ptp">Rapid Acceleration Partners, inc. <br/>
Irving, Texas <br/>
Texas, California 75064 
</p>
          <p className="ptp">Email Address:  <br/>
          hello@rap.ventures  <br/>

</p>
          <p className="ptp">Telephone number: </p>
          <p className="ptp mb-[3rem]">Effective as of September 20, 2022 </p>
          

</div>

</Container>




  
  </Layout>
)

export default IndexPage
